import React, { Component } from 'react';
import { connect } from 'react-redux';

import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'

class About extends Component {
	render() {
    return( 
      <div className="md:mt-20"> 
        <div className="min-h-screen"> 
          <Navbar/>
        </div>
        <Footer/>
      </div>
    )
  }
}
export default connect()(About);