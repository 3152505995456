import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

import icon from '../images/logo_icon.png'
import funtopia from '../images/funtopia.jpg'
import video from '../images/video.gif'
import kick from '../images/kick.gif'
import heart from '../images/heart.png'
import webly_solutions from '../images/webly_solutions.gif'
import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'
import {
  PhotoIcon,
  FilmIcon
} from '@heroicons/react/24/outline'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import "@fontsource/luckiest-guy";


const services = [
  { id: 1, stat: 'Birthdays', emphasis: 'Birthday Wishes', rest: 'to the special one.' },
  { id: 2, stat: 'Anniversary', emphasis: 'Love Messages', rest: 'to the dearest.' },
  { id: 3, stat: 'Holidays', emphasis: 'Warm Wishes', rest: 'to someone we truly care about..' },
  { id: 4, stat: 'Occassions', emphasis: 'Personal Messages', rest: 'to the loved one.' },
]

const footerNavigation = {
  main: [
    { name: 'About', href: '#' },
    { name: 'Contact Us', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/brickamaze/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Youtube',
      href: 'https://www.youtube.com/channel/UCiZhwwN14VVLRwd5ws-bUbQ',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/brickamaze/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}

export default function HomePage() {
  return (
    <div className="bg-primary-color">
      <Navbar/>
      <main>
        {/* Hero section */}
        <div className="lg:mx-auto lg:max-w-screen-2xl bg-primary-color">
          <div className="pt-6 md:pt-8"> 
            <img
              className="mx-auto center h-14 md:h-24 object-cover"
              src="/images/homepage/logo_wording.webp"
              alt="brickAmaze"
            />
            
          </div>
          <div className="relative my-10">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src={funtopia}
                />
                <div
                  className="absolute inset-0 bg-primary-dark"
                  style={{ mixBlendMode: 'multiply' }}
                />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 style={{fontFamily: 'Luckiest Guy'}} className="text-center text-4xl font-extrabold tracking-wider sm:text-5xl lg:text-6xl">
                  <span className="block text-white ">Unleash Your Imagination</span>
                </h1>
                <br/>
                <br/>

                <h2 style={{fontFamily: 'Luckiest Guy'}} className="text-center sm:text-3xl lg:text-4xl font-extrabold tracking-wide text-white">
                  Explore the world of Lego
                </h2>
                <br/>
                <br/>
                <h2 style={{fontFamily: 'Luckiest Guy'}} className="text-center text-3xl font-extrabold tracking-wide text-white">
                  Brought to you by
                </h2>
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl text-white">
                  brickAmaze
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="container min-w-full pt-1 pb-6 mt-20 bg-secondary-orange">
          <div className="w-full sm:text-center md:mx-auto lg:col-span-6 text-left">  
            <h2 style={{fontFamily: 'Luckiest Guy'}} className="my-2 md:my-4 lg:my-6 text-3xl font-extrabold tracking-wide text-primary-dark">
              My Own Creation (MOC)
            </h2> 
            <img
              className="rounded-lg w-full"
              src="/images/homepage/moc.webp"
              alt=""
            />  
            <div className="">
              <div className="mt-6">
                <NavLink to="/mocs" style={{fontFamily: 'Bitter'}} className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-dark hover:bg-primary-color">
                  View More
                </NavLink>
              </div>
            </div>          
          </div>
        </div>
        {/* Logo Cloud */}
        {/*}
        <div className="bg-gray-100">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
              Trusted by over 5 very average small businesses
            </p>
            <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-12" src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" alt="Tuple" />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-12" src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg" alt="Mirage" />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img
                  className="h-12"
                  src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
                  alt="StaticKit"
                />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1">
                <img
                  className="h-12"
                  src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg"
                  alt="Transistor"
                />
              </div>
              <div className="col-span-2 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">
                <img
                  className="h-12"
                  src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg"
                  alt="Workcation"
                />
              </div>
            </div>
          </div>
        </div>
        {*/}
        {/* Alternating Feature Sections */}
        
        <div className="relative pb-0 overflow-hidden">
        {/*}
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-500 to-indigo-600">
                      <PhotographIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      My Lego Creations
                    </h2>
                  </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                    <div class="grid grid-cols-3 divide-x divide-gray-200">
                      <div><p className="text-center mt-4 text-lg text-gray-500">Vignettes</p></div>
                      <div><p className="text-center mt-4 text-lg text-gray-500">Dioramas</p></div>
                      <div><p className="text-center mt-4 text-lg text-gray-500">Buildings</p></div>
                    </div>
                </div> 
                <div className="mt-8 border-t border-gray-200 pt-6">
                </div>
                <p className="mt-4 max-w-3xl text-lg text-black">
                  I like storytelling. 
                </p>
                <p className="mt-4 max-w-3xl text-lg text-black">
                  I use Lego to tell stories through building vignettes (small scene) and dioramas (big scene) around minfigures. 
                </p>
                <div className="mt-6">
                  <a
                    href="/photos"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700"
                  >
                    Explore
                  </a>
                </div>
              </div>
              <div className="mt-12 sm:mt-16">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={superhero}
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        {*/}
          <div className="mb-20 bg-primary-dark">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="py-2 px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div className="mt-6">
                    <h2 style={{fontFamily: 'Luckiest Guy'}} className="text-3xl font-extrabold tracking-wider text-white">
                      Stop Motion Videos
                    </h2>
                    <div className="mt-8 border-t border-primary-color pt-6">
                      <div class="grid grid-cols-3 divide-x divide-primary-color">
                        <div><p style={{fontFamily: 'Bitter'}} className="text-center mt-4 text-lg text-white">Festivals</p></div>
                        <div><p style={{fontFamily: 'Bitter'}} className="text-center mt-4 text-lg text-white">Greetings</p></div>
                        <div><p style={{fontFamily: 'Bitter'}} className="text-center mt-4 text-lg text-white">Short Clip</p></div>
                      </div>
                    </div> 
                    <div className="mt-8 border-t border-primary-color pt-6">
                      <div className="mt-6">
                        <a style={{fontFamily: 'Bitter'}} 
                          href="https://www.youtube.com/channel/UCiZhwwN14VVLRwd5ws-bUbQ"
                          target="_blank"
                          className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-primary-dark bg-primary-color hover:bg-primary-light"
                        >
                          Watch
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={kick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Gradient Feature Section */}
        {/*}
        <div className="bg-gradient-to-r from-purple-600 to-indigo-700">
          <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
            <h2 className="text-3xl font-extrabold text-white tracking-tight">Bible School</h2>
            <p className="mt-4 max-w-3xl text-lg text-purple-200">
              Learn Bible with Lego
            </p>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name}>
                  <div>
                    <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                      <feature.icon className="h-6 w-6 purple-300" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                    <p className="mt-2 text-base text-purple-300">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/*}
        {/* Stats section */}
        <div className="lg:mx-auto lg:max-w-screen-xl bg-primary-color">
          <div className="relative my-10">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src="/images/homepage/bible.gif"
                />
                <div
                  className="absolute inset-0"
                  style={{ mixBlendMode: 'multiply' }}
                />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-24 lg:px-8">
                <h1 style={{fontFamily: 'Luckiest Guy'}} className="mt-32 mb-0 text-center text-4xl font-extrabold tracking-wider sm:text-5xl lg:text-6xl">
                  <span className="block text-white ">Explore the bible with Lego</span>
                </h1>
                <br/>

                <div className="mx-auto text-center">
                  <div className="mt-6">
                    <a style={{fontFamily: 'Bitter'}} 
                      href="https://biblefunclub.com/home"
                      target="_blank"
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-primary-dark bg-primary-light hover:bg-primary-color"
                    >
                      Explore
                    </a>
                  </div>
                </div>    
              </div>
            </div>
          </div>
        </div>
        <div className="mt-0 relative bg-gray-900">
          <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
            <div className="h-full w-full xl:grid xl:grid-cols-2">
              <div className="h-full xl:relative xl:col-start-2">
                <img
                  className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                  src="/images/homepage/greeting.gif"
                  alt="People working on laptops"
                />
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                />
              </div>
            </div>
          </div>
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:gap-x-8">
            <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
              <h2 className="text-sm font-semibold tracking-wide uppercase">
                <span style={{fontFamily: 'Luckiest Guy'}} className="text-3xl font-extrabold tracking-wider bg-primary-color bg-clip-text text-transparent">
                  Greetings
                </span>
              </h2>
              <p style={{fontFamily: 'Luckiest Guy'}} className="mt-3 text-3xl font-extrabold tracking-wider text-white">
                Customized greeting videos
              </p>
              <p style={{fontFamily: 'Bitter'}} className="mt-5 text-lg text-gray-300">
                Connecting heart to heart
              </p>
              <p className="mt-5 text-lg text-gray-300">

              </p>
              <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
                {services.map((item) => (
                  <p style={{fontFamily: 'Bitter'}}  key={item.id}>
                    <span className="block text-2xl font-bold text-white">{item.stat}</span>
                    <span className="mt-1 block text-base text-gray-300">
                      <span className="font-medium text-white">{item.emphasis}</span> {item.rest}
                    </span>
                  </p>
                ))}
              </div>
              <div className="mt-10">
                <a style={{fontFamily: 'Bitter'}} 
                  href="https://www.brickecard.com"
                  target="_blank"
                  className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-primary-dark bg-primary-color hover:bg-primary-light"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* }
        <div className="bg-white">
          <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Ready to get started?</span>
              <span className="block bg-gradient-to-r from-purple-500 to-indigo-600 bg-clip-text text-transparent">
                Get in touch or create an account.
              </span>
            </h2>
            <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
              <a
                href="#"
                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-500 to-indigo-600 hover:from-purple-600 hover:to-indigo-700"
              >
                Learn more
              </a>
              <a
                href="#"
                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-800 bg-indigo-50 hover:bg-indigo-100"
              >
                Explore
              </a>
            </div>
          </div>
        </div>
        {*/}
      </main>

      <Footer/>
    </div>
  )
}