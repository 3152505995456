import React, { Fragment,  Component, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'

import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, TrophyIcon, Cog8ToothIcon, ArrowLeftOnRectangleIcon, FingerPrintIcon } from '@heroicons/react/24/outline'
import { BsEnvelopePaper } from "react-icons/bs";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { BiBible } from "react-icons/bi";
import { TbPhoto } from "react-icons/tb";
import { TbLogout } from "react-icons/tb";
import { MdOutlineContactSupport } from "react-icons/md";
import { RxAvatar } from "react-icons/rx"

import '../stylesheets/text.css';
//import { userFetch, userSignout } from '../actions/userActions';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      showMenu: false,
      showMobileMenu: false, 
      hoveringMenu: false, 
    };
    this.hiddenProfileDivRef = React.createRef()
    this.menuProfileDivRef = React.createRef()
  }

  handleMouseLeaveProfile(){ 
    
    setTimeout(() => { 
      if(this.state.hoveringMenu == false){ 
        this.setState({showMenu: false}) 
      }
    }, 2)
    
  }

  handleMouseLeaveMenu(){ 
    this.setState({showMenu: false}) 
    this.setState({hoveringMenu: false}) 
  }

  handleSignOut(){ 
    this.props.userSignout()
  }

  textClass(href){ 
    // pathname contain the item --- plan/xxxx 
    if(window.location.pathname.includes(href)){ 
      return "text-white font-bold cursor-default"
    }
    else{
      return "text-primary-color hover:text-primary-light md:text-secondary-color md:hover:text-secondary-light" 
    }
  }

  homeClass(){ 
    if(window.location.pathname.includes("/home")){ 
      return "bg-white cursor-default"
    }
    return 'bg-secondary-color cursor-pointer'
  }

  profileWidth(divRef){ 
    if(this.state.profileSize){ 
      
      return this.state.profileSize
    }
    if(divRef.current == null){ 
      return 0 
    }
    this.setState({profileSize: divRef.current.offsetWidth})
    return divRef.current.offsetWidth
  }

  profileItem(){ 
    return(
      <div className="border-t border-gray-200 pt-4 pb-3 pl-6">
        <div className="flex justify-center space-x-8">
          <div className="flex-shrink-0  -ml-6">
            <img
              className="relative h-16 w-16 rounded-full"
              src="/images/avatar_placeholder.webp"
              alt=""
            />
          </div>
          <div className="-mt-2">
            <div className="text-base font-medium text-gray-800">
              <div className="flex default-text text-primary-color hover:text-primary-light focus:outline-none text-base cursor-pointer"
                 onClick={(e) => window.location = "/users/sign_in" } 
              >
                <ArrowLeftOnRectangleIcon className="block h-6 w-6"/>
                <p className="ml-1 md:ml-2">
                  Login
                </p>
              </div>
            </div>
            <div className="text-base font-medium text-gray-800">
              <div className="flex default-text text-primary-color hover:text-primary-light focus:outline-none text-base cursor-pointer"
                 onClick={(e) => window.location = "/users/sign_up" } 
              >
                <FingerPrintIcon className="block h-6 w-6"/>
                <p className="ml-1 md:ml-2">
                  Register
                </p>
              </div>
            </div>
            <div className="text-base font-medium text-gray-800">
              <div className="flex default-text text-primary-color hover:text-primary-light focus:outline-none text-base cursor-pointer"
                 onClick={(e) => window.location = "/user/avatar" } 
              >
                <Cog8ToothIcon className="block h-6 w-6"/>
                <p className="ml-1 md:ml-2">
                  Avatar
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  dropDownMenu(){ 
    return(
      <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-primary-dark py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        onMouseOver={() => this.setState({hoveringMenu: true}) }
        onMouseLeave={this.handleMouseLeaveMenu.bind(this)} 
      >
        <Menu.Item>
          {({ active }) => (
            <div className="text-primary-color hover:text-primary-light flex justify-end space-x-4 p-2 mr-2 text-right cursor-pointer"
              onClick={(e) => window.location = "/users/sign_in" } 
            > 
              <div className="my-auto"> 
                <ArrowLeftOnRectangleIcon className="block h-6 w-6"/>
              </div>
              <div> 
                <button className="default-text focus:outline-none text-xl">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login
                </button>
              </div>
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div className="text-primary-color hover:text-primary-light flex justify-end space-x-4 p-2 mr-2 text-right cursor-pointer"
              onClick={(e) => window.location = "/users/sign_up" } 
            > 
              <div className="my-auto"> 
                <FingerPrintIcon className="block h-6 w-6"/>
              </div>
              <div> 
                <button className="default-text focus:outline-none text-xl">
                  Register
                </button>
              </div>
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div className="text-primary-color hover:text-primary-light flex justify-end space-x-4 p-2 mr-2 text-right cursor-pointer"
              onClick={(e) => window.location = "/user/avatar" } 
            > 
              <div className="my-auto"> 
                <RxAvatar className="block h-6 w-6"/>
              </div>
              <div> 
                <button className="default-text focus:outline-none text-xl">
                  Avatar
                </button>
              </div>
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    )
  }

  profilePhoto(){ 
    return( 
      <img className="rounded-full"
        src="/images/avatar_placeholder.webp"
        alt=""
      />
    )
  }

  componentDidUpdate(prevProps) {
    if(this.props.signed_out == true){ 
       window.location = "/home"
    }
    console.log(`divRef: ${this.hiddenProfileDivRef.current}`)
  }

  componentDidMount() {
    //if(!this.props.userLoaded){
    //  this.props.userFetch();
    //}
  }

  render() {
    return (
      <div>
        <Disclosure as="nav" className="bg-white shadow">
          {({ open }) => (
            <>
              <div className="w-full bg-secondary-dark fixed top-0 z-50">
                <div className="lg:max-w-7xl mx-auto">
                  <div className="items-center px-4 py-1 md:py-2 lg:px-8 md:justify-start md:space-x-10">
                    <div className="flex md:h-20 h-16 justify-between">
                      <div className="flex">
                        <div className="flex flex-shrink-0 items-center">
                          <img
                            onClick={(e) => window.location = "/home" } 
                            className={"md:h-16 h-12 " + this.homeClass()}
                            src="/images/logo_full.webp"
                            alt=""
                          />
                          <div className="hidden md:ml-6 md:flex md:space-x-8">
                            {/* Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                            <NavLink to="/mocs" className={"default-text flex flex-col items-center justify-center focus:outline-none text-2xl font-medium " + this.textClass("/mocs")}>
                              <TbPhoto className="text-3xl"/>
                              MOCs
                            </NavLink>
                            <NavLink to={{pathname: "https://www.youtube.com/@brickamaze"}} target="_blank" className={"default-text flex flex-col items-center justify-center focus:outline-none text-2xl font-medium " + this.textClass("/videos")}>
                              <MdOutlineOndemandVideo className="text-3xl"/>
                              Videos
                            </NavLink>
                            <NavLink to={{pathname: "https://biblefunclub.com/home"}} target="_blank" className={"default-text flex flex-col items-center justify-center focus:outline-none text-2xl font-medium " + this.textClass("/bible")}>
                              <BiBible className="text-3xl"/>
                              Bible
                            </NavLink>
                            <NavLink to={{pathname: "https://brickecard.com"}} target="_blank" className={"default-text flex flex-col items-center justify-center focus:outline-none text-2xl font-medium " + this.textClass("/greetings")}>
                              <BsEnvelopePaper className="text-3xl"/>
                              Greetings
                            </NavLink>
                            <NavLink to="/contact-us" className={"default-text flex flex-col items-center justify-center focus:outline-none text-2xl font-medium " + this.textClass("/contact-us")}>
                              <MdOutlineContactSupport className="text-3xl"/>
                              Contact
                            </NavLink>
                          </div>
                        </div>
                        <div className="top-0 w-full h-16 md:h-24"/>
                      </div>
                      <div className="-mr-2 flex items-center md:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                          onClick={() => this.setState({showMobileMenu: !this.state.showMobileMenu})}
                        >
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                          ) : (
                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                          )}
                        </Disclosure.Button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="fixed top-18 z-50 w-full bg-primary-dark md:hidden">
                <div className="space-y-1 pt-2 pb-3">
                  {/* Current: "bg-blue-50 border-blue-500 text-blue-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                  <div className="flex justify-center space-x-4 md:space-x-8">
                    {/* Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <NavLink to="/mocs" className={"default-text flex flex-col items-center justify-center focus:outline-none text-base md:text-2xl font-medium " + this.textClass("/mocs")}>
                      <TbPhoto className="text-xl md:text-3xl"/>
                      MOCs
                    </NavLink>
                    <NavLink to={{pathname: "https://www.youtube.com/@brickamaze"}} target="_blank" className={"default-text flex flex-col items-center justify-center focus:outline-none text-base md:text-2xl font-medium " + this.textClass("/videos")}>
                      <MdOutlineOndemandVideo className="text-xl md:text-3xl"/>
                      Videos
                    </NavLink>
                    <NavLink to={{pathname: "https://biblefunclub.com/home"}} target="_blank" className={"default-text flex flex-col items-center justify-center focus:outline-none text-base md:text-2xl font-medium " + this.textClass("/bible")}>
                      <BiBible className="text-xl md:text-3xl"/>
                      Bible
                    </NavLink>
                    <NavLink to={{pathname: "https://brickecard.com"}} target="_blank" className={"default-text flex flex-col items-center justify-center focus:outline-none text-base md:text-2xl font-medium "+ this.textClass("/greetings")}>
                      <BsEnvelopePaper className="text-xl md:text-3xl"/>
                      Greetings
                    </NavLink>
                    <NavLink to="/contact-us" className={"default-text flex flex-col items-center justify-center focus:outline-none text-base md:text-2xl font-medium " + this.textClass("/contact-us")}>
                      <MdOutlineContactSupport className="text-xl md:text-3xl"/>
                      Contact
                    </NavLink>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div className="top-0 w-full h-18 md:h-24" />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
    
  }
};

export default connect(mapStateToProps, {
  //userSignout, 
  //userFetch
})(NavBar);