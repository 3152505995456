import React, { Component } from 'react';
import { connect } from 'react-redux';

import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'

class Videos extends Component {
	render() {
    return( 
      <div> 
        <div className="min-h-screen"> 
          <Navbar/>
        </div>
        <Footer/>
      </div>
    )
  }
}
export default connect()(Videos);