import React, { Component } from 'react';
import { connect } from 'react-redux';


import HomePage from '../pages/HomePage'

class Home extends Component {

  render() {
    return( 
      <HomePage/>
    )
  }
}


export default connect()(Home);