import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './Home'; 
import Photos from './Photos';
import Videos from './Videos';
import Services from './Services';
import About from './About';
import ContactUs from './ContactUs';

import RootReducer from '../reducers/RootReducer';

class App extends React.Component {

  render() {
    const store = createStore(RootReducer, {}, applyMiddleware(ReduxThunk));
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div>
            <Switch>   
              <Route exact path="/home" component={Home} />  
              <Route exact path="/mocs" component={Photos} />
              <Route exact path="/videos" component={Videos} />
              <Route exact path="/services" component={Services} />
              <Route exact path="/about" component={About} />
              <Route exact path="/contact-us" component={ContactUs} />
            </Switch>
          </div>
        </BrowserRouter> 
      </Provider>
    );
  }
}

export default App;